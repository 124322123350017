import React, { useState } from "react";
import axios from "axios";
import { IoIosClose } from "react-icons/io";
import { MdClose } from "react-icons/md";
import LineLogo from "../../../assets/LineLogo.png";
import pdfIcon from "../../../assets/PDFLogo.png";
import { ToastContainer, toast } from "react-toastify";

import { uploadFile } from "react-s3";
import { useNavigate } from "react-router-dom";

function AddDocument({ onClickHandler }) {
  const [file, setfile] = useState();
  const [docName, setDocName] = useState({});
  const [loading, setLoading] = useState(false);
  const [document, setDocument] = useState({ docName: "" });
  const token = localStorage.getItem("JWT");
  const userID = localStorage.getItem("user_id");

  const navigateBack = useNavigate();
  //----------------For collecting Documents using MAP-----------------------
  const [collectEmployeeDocument, setCollectEmployeeDocument] = useState([]);

  //----------------For collecting Documents and storing to API--------------------
  const [collectDocumentsState, setCollectDocumentState] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [filename, setFilename] = useState("");

  //-----------------------Config for AWS--------------------------------------
  const config = {
    bucketName: "accudao-dev",
    region: "ap-south-1",
    accessKeyId: "AKIAWBYPBBMBPIHEEB44",
    secretAccessKey: "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",
    s3Url: "https://accudao-dev.s3.ap-south-1.amazonaws.com/" /* optional */,
  };

  //------------------------------Add file---------------------------------------------------------------
  const collectDocuments = async (documentsEmployee) => {
    console.log(documentsEmployee);

    return axios.post(
      "https://upkeep-be-uat.devtomaster.com/add_documents",
      {
        user_id: JSON.stringify(userID),
        documents: JSON.stringify(documentsEmployee),
      },
      {
        headers: {
          JWT: token,
          "Content-Type": "application/json",
        },
      }
    );
  };

  const collectEmployeeDocumentFunction = (name, link) => {
    setCollectDocumentState({
      ...collectDocumentsState,
      [name]: link,
    });
    console.log("Doc State", collectDocumentsState);
  };

  const onFileDrop = (e, name) => {
    let extension = e.target.files[0].name.split(".").pop();
    if (
      extension.toLowerCase() === "pdf" ||
      extension.toLowerCase() === "png"
    ) {
      setSelectedFile(e.target.files[0]);
      setFilename(docName);
      console.log("File name is", filename);
      if (e.target.files[0].size / 1024 <= 2048) {
        setDocument({
          ...document,
          [name]: e.target.value.split(/(\\|\/)/g).pop(),
        });
        console.log("File name is", docName);
        console.log("File name pop", document);
        //-------AWS uploadfile-----------------
        uploadFile(e.target.files[0], config)
          .then((data) => {
            console.log(data.location);
            collectEmployeeDocumentFunction(name, data.location);
          })
          .catch((err) => console.error(err));

        let documentCopy = [...collectEmployeeDocument];
        documentCopy.push({ [name]: e.target.files[0] });
        console.log("Document Copy", documentCopy);
        setCollectEmployeeDocument(documentCopy);
      } else {
        toast("File size limit exceeds!", {
          type: "error",
          position: "top-right",
          autoClose: 2000,
          theme: "colored",
        });
        setDocument({
          ...document,
          [name]: "",
        });

        // setComplete(complete - 100.0/noOfDocuments);
        collectEmployeeDocumentFunction(docName, document.location);
      }
    } else {
      toast("File type must PDF or PNG!", {
        type: "error",
        position: "top-right",
        autoClose: 2000,
        theme: "colored",
      });
    }
  };
  function removeFile(name) {
    setDocument({
      ...document,
      [name]: "",
    });
  }

  const collectDocName = (e) => {
    const { value } = e.target;

    setDocName(() => {
      return {
        ...docName,
        value,
      };
    });

    console.log("Doc Name is", docName);
  };

  function UploadInput(name) {
    return (
      <>
        <div className=" mb-2 sm:mb-5">
          <label className=" block text-[#5F5F5F] text-md ">
            Upload Document
          </label>
          {!document?.[name] ? (
            <div className="bg-[#EDF2FF] cursor-pointer h-20 w-11/12 rounded-lg border-dashed border-2 mt-1  border-[#B4C9FF] flex justify-center items-center hover:cursor-pointer">
              <div className="absolute">
                <div className="flex items-center">
                  <p className="block text-[#2F5FDA] font-bold p-[4px] ">
                    Click to upload
                  </p>
                  <p className="block text-[#666666]">
                    or drag and drop <br />
                  </p>
                </div>
                <p className="block text-sm text-slate-500 text-center ">
                  PDF file
                </p>
              </div>
              <input
                type="file"
                class="h-full w-full opacity-0"
                name=""
                accept=".png,.pdf"
                onChange={(e) => {
                  onFileDrop(e, name);
                }}
              />
            </div>
          ) : (
            <div className=" h-20 font-medium  lg:w-11/12 rounded-lg border-2 border-[#B4C9FF] flex  ">
              <div className="flex w-full  ">
                <div className="flex w-full items-center ml-6 ">
                  <img className=" " src={pdfIcon} alt="" />
                  <div className="  ml-2 w-5/6 ">
                    <p className="block text-sm  font-semibold mx-1 items-center ">
                      {document?.[name]}
                    </p>
                    <div className="flex items-center">
                      <div className="h-2 w-full  bg-gray-300 rounded overflow-hidden mx-1 ">
                        <div
                          style={{ width: `${100}%` }}
                          className={`h-full  bg-green-600  `}
                        />
                      </div>
                      <div className="text-xs flex items-center  ">
                        100%
                        <span>
                          <MdClose
                            onClick={() => {
                              removeFile(name);
                            }}
                            size="1.4rem"
                            className=" ml-2 text-red-600 cursor-pointer"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
  const Confirm = () => {
    setLoading(true);
    collectDocuments(collectDocumentsState)
      .then(function (response) {
        console.log("response ", response);
        setLoading(false);
        if (response?.data) {
          toast(response?.data.msg, {
            type: "success",
            position: "top-right",
            autoClose: 2000,
            theme: "colored",
          });
          console.log("success");
          navigateBack("/UserManagement/edit/:id");
        } else {
          toast("Try again", {
            type: "error",
            position: "top-right",
            autoClose: 2000,
            theme: "colored",
          });
        }
      })
      .catch(function (error) {
        setLoading(false);
        toast("Something went wrong!", {
          type: "error",
          position: "top-right",
          autoClose: 2000,
          theme: "colored",
        });
        console.log(error);
      });
  };
  return (
    <div
      style={{
        position: "fixed",
        left: "0",
        right: "0",
        top: "0",
        bottom: "0",
        height: "100vh",
        width: "100%",
        background: " rgba(0, 0, 0, 0.5)",
        zIndex: "1218",
      }}
    >
      <div className="mx-auto sm:px-10 top-10 mt-10 md:container md:mx-auto  justify-between items-center">
        <div
          style={{
            width: window.innerWidth > 600 ? "500px" : window.innerWidth - 20,
            height: window.innerHeight > 700 ? "650px" : "580px",

            position: "fixed",
            background: "white",
            left: "0",
            top: "0",
            bottom: "0",
            right: "0",
            margin: "auto",
            borderRadius: "10px",
          }}
          className="shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] p-2"
        >
          <IoIosClose
            className="float-right cursor-pointer"
            size={25}
            onClick={onClickHandler}
          />
          <div style={{ marginLeft: "35px" }}>
            <div>
              <h1 className="text-lg sm:text-2xl font-bold mb-3 pt-1">
                Add Document
              </h1>
            </div>
            <div>
              <p className="text-sm text-[#5F5F5F] float-left w-3/4  mb-3 mt-3md:ml-2">
                Please upload the following documents
              </p>
            </div>
            <div className="mb-5">
              <img src={LineLogo} alt="" />
            </div>
            <div className=" mb-2 sm:mb-5">
              <label className="text-[#5F5F5F] text-md mb-1">
                Document name
              </label>
              <input
                className="text-slate-700 h-9 w-11/12 p-2 border mt-1 rounded-lg hover:border-[#3C69DF]"
                type="text"
                placeholder="Document name"
                onChange={collectDocName}
              />
            </div>
            <UploadInput name={docName} />
          </div>
          <div>
            <button
              className="w-11/12 h-12 mt-3 absolute bottom-10 mx-2 border rounded-lg bg-[#3C69DF] hover:bg-[#284ba3] text-white text-bold"
              onClick={() => {
                Confirm();
              }}
            >
              {loading ? (
                <svg
                  class="inline mr-2 w-8 h-8  animate-spin text-white fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              ) : (
                "Confirm"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddDocument;
