import axios from "axios";

const token = localStorage.getItem("JWT");
const userID = localStorage.getItem("user_id");

export function bankDetails({
  bankName,
  accountName,
  accountNumber,
  ifscCode,
  branchName,
}) {
  console.log({
    bankName,
    accountName,
    accountNumber,
    ifscCode,
    branchName,
  });

  return axios.post(
    "https://upkeep-be-uat.devtomaster.com/add_bank_details",
    {
      user_id: localStorage.getItem("user_id"),
      bank_name: bankName,
      acc_name: accountName,
      acc_number: accountNumber,
      ifsc_code: ifscCode,
      branch: branchName,
    },
    {
      headers: {
        JWT: localStorage.getItem("JWT"),
      },
    }
  );
}
