import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function RequestList({ employees, setEmployees }) {
  const [ShowActions, setShowActions] = useState(false);
  const [page, setPage] = useState(1);
  const navigation = useNavigate();

  useEffect(() => {
    // console.log(employees);
    document.body.addEventListener("click", (event) => {
      if (event?.path?.[1]?.id !== "actions") {
        setShowActions(false);
      }
    });
  }, []);

  function getStatus(status, date, id) {
    if (!status) return;

    if (status === "yet-to-login" || status === "yet to login") {
      return (
        <>
          <td className="flex pt-4 w-full font-medium  ">
            <span className="py-1 px-2 bg-purple-100 text-purple-600 rounded">
              {status}
            </span>
          </td>
          <td className="py-4 px-6">--</td>
        </>
      );
    }
    if (status === "In-progress") {
      return (
        <>
          <td className="flex pt-4 font-medium ">
            <span className="py-1 px-2 bg-orange-100 text-orange-600 rounded">
              {status}
            </span>
          </td>
          <td
            className="py-4 px-6 text-blue-500 underline cursor-pointer font-medium"
            onClick={() => navigation(`/ApprovalRequests/view/${id}`)}
          >
            View
          </td>
        </>
      );
    }

    if (
      status === "Re-verification" ||
      status === "re-verification" ||
      status === "RE-VERIFICATION" ||
      status === "Re-Verification"
    ) {
      return (
        <>
          <td className="flex w-28 pt-4 font-medium  ">
            <span className="py-1 px-2 bg-red-100 text-red-600 rounded">
              {status}
            </span>
          </td>
          <td
            className="py-4 px-6 text-blue-500 underline cursor-pointer font-medium"
            onClick={() => navigation(`/ApprovalRequests/view/${id}`)}
          >
            View Report
          </td>
        </>
      );
    }

    if (status === "Verified" || status === "verified") {
      return (
        <>
          <td className="flex pt-4 font-medium">
            <span className="py-1 px-2 bg-blue-100 text-blue-600 rounded">
              {status}
            </span>
          </td>
          <td
            className="py-4 px-6 text-blue-500 underline cursor-pointer font-medium"
            onClick={() => navigation(`/ApprovalRequests/view/${id}`)}
          >
            View
          </td>
        </>
      );
    }

    if (status === "Completed" || status === "completed") {
      return (
        <>
          <td className="flex pt-4 font-medium  ">
            <span className="py-1 px-2 bg-green-100 text-green-600 rounded">
              {status}
            </span>
          </td>
          <td
            className="py-4 px-6 text-blue-500 underline cursor-pointer font-medium"
            onClick={() => navigation(`/ApprovalRequests/view/${id}`)}
          >
            Verify
          </td>
        </>
      );
    }
  }

  const selectPageHandler = (selectedPage) => {
    if (
      selectedPage >= 1 &&
      selectedPage <= employees.length &&
      selectedPage !== page
    )
      setPage(selectedPage);
  };

  return (
    <>
      {employees
        .slice(page * 10 - 10, page * 10)
        ?.map(
          ({
            user_id,
            username,
            emp_id,
            date_of_joining,
            dept,
            emp_type,
            email,
            emp_status,
            onboarding_status,
            last_updated,
          }) => {
            return (
              <tr
                key={user_id}
                className="bg-white text-center border-b border-slate-300  text-slate-700"
              >
                <th
                  scope="row"
                  className="py-4 font-medium whitespace-nowrap"
                >
                  {username}
                </th>
                <td className="py-4">{emp_id}</td>
                <td className="py-4">{date_of_joining}</td>
                <td className="py-4">
                  {dept.charAt(0).toUpperCase() + dept.slice(1)}
                </td>{" "}
                <td className="py-4">
                  {emp_type.charAt(0).toUpperCase() + emp_type.slice(1)}
                </td>
                <td className="py-4">{email}</td>
                {getStatus(onboarding_status, last_updated, user_id)}
              </tr>
            );
          }
        )}

      {employees.length > 0 && (
        <div className="p-3 ">
          <span
            className={`${
              page > 1
                ? "visible cursor-pointer font-medium text-black"
                : "disabled"
            }`}
            onClick={() => selectPageHandler(page - 1)}
          >
            Prev
          </span>
          {[...Array(Math.ceil(employees.length / 10))].map((_, i) => {
            return (
              <span
                className={`p-2 mx-1 cursor-pointer border rounded-lg ${
                  page === i + 1 ? "bg-gray-300" : ""
                }`}
                onClick={() => selectPageHandler(i + 1)}
                key={i}
              >
                {i + 1}
              </span>
            );
          })}
          <span
            className={` ${
              page < Math.ceil(employees.length / 10)
                ? "visible cursor-pointer font-medium text-black"
                : "disabled"
            }`}
            onClick={() => {
              page < Math.ceil(employees.length / 10) &&
                selectPageHandler(page + 1);
            }}
          >
            Next
          </span>
        </div>
      )}
    </>
  );
}

export default RequestList;
