import React, { useEffect, useState } from "react";
import { RiCheckFill } from "react-icons/ri";

function SortByDropDown({ sort, setSort }) {
  const [showFilter, setShowFilter] = useState(false);

  // console.log(sort);
  // useEffect(() => {
  //   document.body.addEventListener("click", (event) => {
  //     console.log(event?.path?.[3]?.id);
  //     if (event?.path?.[3]?.id !== "Sort By") {
  //       setShowFilter(false);
  //     }
  //   });
  // }, []);

  // useEffect(()=>{
  //   console.log(showFilter);
  // })

  const FilterItem = ({ title, name }) => {
    // console.log(title,name);
    return (
      <div
        onClick={() => {
          setSort(name);
          setShowFilter(false);
        }}
        className="flex justify-between items-center block cursor-pointer px-4 py-3 capitalize transition-colors duration-200  hover:bg-gray-100"
      >
        <p class=" text-sm font-medium text-gray-600  ">{title}</p>
        {sort === name ? <RiCheckFill className="text-green-800" /> : null}
      </div>
    );
  };

  return (
    <div id={"SortBy"} class="flex justify-center font-OpenSans">
      <div id={"SortBy"} class="relative inline-block ">
        <button
          onClick={() => setShowFilter(!showFilter)}
          class="relative whitespace-nowrap z-10 flex items-center p-3 text-sm text-gray-600 bg-white border border-slate-200 rounded-md focus:border-blue-500 focus:ring-opacity-40  focus:ring-blue-300  focus:ring   focus:outline-none"
        >
          <span class="mx-1 text-base font-semibold">Sort By</span>
          <svg
            class="w-5 h-5 mx-1"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 15.713L18.01 9.70299L16.597 8.28799L12 12.888L7.40399 8.28799L5.98999 9.70199L12 15.713Z"
              fill="currentColor"
            ></path>
          </svg>
        </button>
        {showFilter ? (
          <div class="absolute sm:right-0 z-20 w-56 py-2 mt-2 overflow-hidden bg-white rounded-md shadow-xl ">
            <div class="mx-1 p-3 -mt-2">
              <h1 class="text-sm font-semibold text-gray-400 ">Sort By</h1>
            </div>
            <FilterItem title="Sort Alphabetically" name="Alphabetical" />
            <FilterItem title="Sort by Employee Type Esc" name="EmpTypeAsc" />
            <FilterItem title=" Sort by Employee Type Desc" name="EmpTypeDesc" />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default SortByDropDown;
