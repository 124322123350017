import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import LineTwo from "../../assets/Page-two.png";
import uparrow from "../../assets/uparrow.png";

import { bankDetails } from "../../API/bankDetails.js";

import { toast, ToastContainer } from "react-toastify";
import { useLocation } from "react-router-dom";

const InfoThree = ({ complete, setComplete }) => {
  const navigateDetails = useNavigate();
  const location = useLocation();
  const onboarding_status = location.state.status;

  const [bankInfo, setBankInfo] = useState({
    bankName: "",
    accountName: "",
    accountNumber: "",
    ifscCode: "",
    branchName: "",
  });
  const [validation, setValidation] = useState({
    bankName: "",
    accountName: "",
    accountNumber: "",
    ifscCode: "",
    branchName: "",
  });

  const [loading, setLoading] = useState(false);
  const [checkBox, setCheckBox] = useState(false);

  useEffect(() => {
    console.log(location.state.bank_details);
    setBankInfo({
      bankName: location.state.bank_details.bank_name,
      accountName: location.state.bank_details.account_name,
      accountNumber: location.state.bank_details.account_number,
      ifscCode: location.state.bank_details.ifsc_code,
      branchName: location.state.bank_details.branch,
    });
    if (onboarding_status === "Completed") {
      setComplete(100);
    }
    window.scrollTo(0, 0);
  }, []);

  const checkValidation = () => {
    let errors = validation;

    // //Bank Name validation
    // if (bankInfo.bankName == "") {
    //   errors.bankName = "*Bank name is required";
    // } else {
    //   errors.bankName = "";
    // }

    // //Account Name validation
    // if (bankInfo.accountName == "") {
    //   errors.accountName = "*Account name is required";
    // } else {
    //   errors.accountName = "";
    // }

    // //Account Number validation
    // if (bankInfo.accountNumber == "") {
    //   errors.accountNumber = "*Account number is required";

    // } else if (isNaN(bankInfo.accountNumber)) {
    //   errors.accountNumber = "Account number must be a number";
    // } else {
    //   errors.accountNumber = "";
    // }

    // //IFSC Code validation
    // if (bankInfo.ifscCode == "") {
    //   errors.ifscCode = "*IFSC Code is required";
    // } else if (bankInfo.ifscCode.length > 10) {
    //   errors.ifscCode = "*IFSC Code is too long";
    // } else if (bankInfo.ifscCode.length < 10) {
    //   errors.ifscCode = "*IFSC Code is too short";
    // } else {
    //   errors.ifscCode = "";
    // }

    // //Branch Name validation
    // if (bankInfo.branchName == "") {
    //   errors.branchName = "*Branch Name is required";
    // } else {
    //   errors.branchName = "";
    // }

    setValidation(errors);
  };

  // useEffect(() => {
  //   checkValidation();
  // }, [bankInfo]);

  function checkInput() {
    let parentNode = document
      .querySelector("#parentDiv")
      .querySelectorAll("#inputText");
    let count_field = 0;
    for (let i = 0; i < parentNode.length; i++) {
      if (parentNode[i].value != "") count_field++;
    }
    let percentage = (100 / 5) * count_field;
    setComplete(percentage);
  }

  const Submit = () => {
    if (checkBox === false) {
      //setLoading(false);
      toast("Please fill the checkbox", {
        type: "error",
        position: "top-right",
        autoClose: 2000,
        theme: "colored",
      });
    } else if (complete < 100) {
      toast("Please fill all the fields", {
        type: "error",
        position: "top-right",
        autoClose: 2000,
        theme: "colored",
      });
    } else {
      navigateDetails("/congratulations");
    }
  };

  const saveChanges = () => {
    setLoading(true);
    bankDetails(bankInfo)
      .then(function (response) {
        console.log(response);
        setLoading(false);
        if (
          checkBox === true &&
          bankInfo.accountName.length != "" &&
          bankInfo.accountNumber.length != "" &&
          bankInfo.ifscCode.length != "" &&
          bankInfo.accountNumber.length === 16 &&
          bankInfo.ifscCode.length === 11 &&
          isNaN(bankInfo.accountNumber)
        ) {
          toast(response?.data.msg, {
            type: "success",
            position: "top-right",
            autoClose: 2000,
            theme: "colored",
          });
          console.log("success");
        } else if (checkBox === false) {
          toast("Please fill checkbox", {
            type: "error",
            position: "top-right",
            autoClose: 2000,
            theme: "colored",
          });
        } else if (complete < 100) {
          toast("Please fill all the fields", {
            type: "error",
            position: "top-right",
            autoClose: 2000,
            theme: "colored",
          });
        } 
        // else if (bankInfo.accountNumber.length > 15) {
        //   toast("Account number is too long", {
        //     type: "error",
        //     position: "top-right",
        //     autoClose: 2000,
        //     theme: "colored",
        //   });
        // }
         else if (isNaN(bankInfo.accountNumber)) {
          toast("Account number must be a number", {
            type: "error",
            position: "top-right",
            autoClose: 2000,
            theme: "colored",
          });
        } else if (bankInfo.accountNumber.length < 15) {
          toast("Account number is too short", {
            type: "error",
            position: "top-right",
            autoClose: 2000,
            theme: "colored",
          });
        } else if (bankInfo.ifscCode.length > 11) {
          toast("IFSC Code is too long", {
            type: "error",
            position: "top-right",
            autoClose: 2000,
            theme: "colored",
          });
        } else if (bankInfo.ifscCode.length < 11) {
          toast("IFSC Code is too short", {
            type: "error",
            position: "top-right",
            autoClose: 2000,
            theme: "colored",
          });
        } else {
          toast("Try again", {
            type: "info",
            position: "top-right",
            autoClose: 2000,
            theme: "colored",
          });
        }
      })
      .catch(function (error) {
        setLoading(false);
        toast("Something went wrong!", {
          type: "error",
          position: "top-right",
          autoClose: 2000,
          theme: "colored",
        });
        console.log(error);
      });
  };

  return (
    <div className="font-OpenSans">
      {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
      {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}

      <div
        className="grid lg:grid-cols-2 md:grid-cols-1 xs:grid-cols-1 mt-5"
        id="parentDiv"
        onChange={checkInput}
      >
        {/*Section 1 -- Col 1 */}

        <div className="grid grid-cols-1 lg:ml-48 xs:ml-5">
          <div className="w-fit">
            <h1 className="text-[#000000] font-bold text-2xl">Bank Info</h1>
            <h2 className="hidden text-[#A1A1A1] lg:flex">
              Provide your bank info
            </h2>
          </div>
        </div>

        {/*Section 1 -- Col 2 */}

        <div className="grid grid-cols-1 items-center lg:items-start">
          <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1  xs:grid-cols-1">
            <div className="grid lg:p-5 xs:p-2 col-span-2">
              <div className="flex flex-col ">
                <label className="">Select bank name</label>
                <select
                  className="text-xs lg:w-full border-2 rounded-lg p-2.5 border-[#AEAEAE] hover:border-[#3C69DF]"
                  placeholder="Select"
                  id="inputText"
                  name="bankName"
                  value={bankInfo?.bankName}
                  disabled={onboarding_status === "Completed" ? true : false}
                  onChange={(e) =>
                    setBankInfo({ ...bankInfo, bankName: e.target.value })
                  }
                >
                  <option value="">Select</option>
                  <option value="HDFC">HDFC Bank</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>

            <div className="flex flex-col lg:p-5 xs:p-2">
              <label className="pb-2">Account name</label>
              <input
                className="text-xs border-2 rounded-lg p-2.5 border-[#AEAEAE] hover:border-[#3C69DF]"
                type="text"
                id="inputText"
                name="accountName"
                placeholder="Account Name"
                disabled={onboarding_status === "Completed" ? true : false}
                value={bankInfo?.accountName}
                required
                onChange={(e) =>
                  setBankInfo({ ...bankInfo, accountName: e.target.value })
                }
              />
            </div>

            <div className="flex flex-col lg:p-5 xs:p-2">
              <label className="pb-2">Account Number</label>
              <input
                className="text-xs border-2 rounded-lg p-2.5 border-[#AEAEAE] hover:border-[#3C69DF]"
                type="text"
                id="inputText"
                name="accountNumber"
                pattern="[0-9]"
                placeholder="Account Number"
                disabled={onboarding_status === "Completed" ? true : false}
                value={bankInfo?.accountNumber}
                required
                onChange={(e) =>
                  setBankInfo({ ...bankInfo, accountNumber: e.target.value })
                }
              />
            </div>
            {/* Section 2 -- Col 3 */}

            <div className="flex flex-col lg:p-5 xs:p-2">
              <label className="pb-2">IFSC code</label>
              <input
                className="text-xs border-2 rounded-lg p-2.5 border-[#AEAEAE] hover:border-[#3C69DF]"
                type="text"
                id="inputText"
                placeholder="IFSC code"
                name="ifscCode"
                required
                disabled={onboarding_status === "Completed" ? true : false}
                value={bankInfo?.ifscCode}
                onChange={(e) => {
                  setBankInfo({ ...bankInfo, ifscCode: e.target.value });
                }}
              />
            </div>

            <div className="flex flex-col lg:p-5 xs:p-2">
              <label className="pb-2">Branch Address</label>
              <input
                className="text-xs border-2 rounded-lg p-2.5 border-[#AEAEAE] hover:border-[#3C69DF]"
                type="text"
                id="inputText"
                placeholder="Branch Address"
                name="branchName"
                disabled={onboarding_status === "Completed" ? true : false}
                required
                value={bankInfo?.branchName}
                onChange={(e) =>
                  setBankInfo({ ...bankInfo, branchName: e.target.value })
                }
              />
            </div>
          </div>
        </div>
      </div>
      {/* Line in between */}

      <img
        className="scale-50 lg:mt-16 lg:flex lg:ml-44"
        src={LineTwo}
        alt=""
      />

      {/* FOOTER */}

      {onboarding_status !== "Completed" ? (
        <div className="flex flex-col px-auto lg:pl-24 items-center">
          <div className="hidden lg:ml-28 lg:mt-5">
            <img src={LineTwo} alt="" />
          </div>

          <div className="flex flex-row mt-2 lg:ml-16 lg:pr-5 lg:px-5 md:px-10 xs:px-5">
            <input
              className="scale-105 lg:mt-6 lg:ml-4 lg:scale-125"
              type="checkbox"
              required
              onClick={() => {
                setCheckBox(true);
              }}
            />
            <label className=" mt-8 lg:mt-6 text-xs text-[#5F5F5F] pl-2 lg:text-base lg:w-full">
              I certify that the above facts are true to the best of my
              knowledge and I understand that I subject myself to disciplinary
              action in the event that the above facts are found to be
              falsified.{" "}
            </label>
          </div>
          <div className="flex flex-row  w-full justify-end lg:pr-5">
            <button className="mt-4 w-72 h-12 ml-10 border-2 rounded-lg bg-[#3B69DF] hover:bg-[#1c377e] text-white lg:mt-4 lg:ml-6 lg:h-12 lg:w-96"
              onClick={() => {
                saveChanges(true);
                Submit();
              }}
            >
              {loading ? (
                <svg
                  class="inline mr-2 w-8 h-8  animate-spin text-white fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              ) : (
                "Save changes and Submit"
              )}
            </button>          </div>
        </div>
      ) : (
        <div className="flex flex-row justify-end">

        </div>
      )}
      <ToastContainer />
      {/* Main Div closing tag */}

      <footer class="h-20 xs:h-2/4 mt-5 border-2 flex justify-around">
        <div className="flex lg:flex-row lg:gap-x-10 xs:flex-col xs:my-2 mt-4 xs:text-xs sm:text-xs lg:text-base">
          <p className="xs:mx-5"> Upkeep</p>
          <li className=""> Privacy</li>
          <li className=""> Terms</li>
          <li className=""> Company details</li>
        </div>
        <div className="flex lg:flex-row xs:flex-col gap-x-4 xs:grid-cols-1 xs:my-2 xs:mx-10  md:mr-7 mt-4 xs:text-xs sm:text-xs md:text-base lg:text-base">
          <p className="">English (IN) </p>
          <div className="flex">
            <p className="">Support & resources</p>
            <img src={uparrow} className="h-2 pl-3 mt-2" />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default InfoThree;
