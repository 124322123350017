import axios from "axios";

const token = localStorage.getItem("JWT");
const userID = localStorage.getItem("user_id");
const roleID = localStorage.getItem("role_id");

export const fetchRole = () => {
  return axios.post(
    "https://upkeep-be-uat.devtomaster.com/fetch_role",
    {
      user_id: localStorage.getItem("user_id"),
    },
    {
      headers: {
        JWT: localStorage.getItem("JWT"),
        "Content-Type": "application/json",
      },
    }
  );
};

export function fetchEmployeeDetailsOne() {
  return axios.post(
    "https://upkeep-be-uat.devtomaster.com/fetch_employee_detail_one",
    {
      user_id: localStorage.getItem("user_id"),
    },
    {
      headers: {
        JWT: localStorage.getItem("JWT"),
        "Content-Type": "application/json",
      },
    }
  );
}
