import React, { useEffect, useState } from "react";
import Page from "../common/Page";

import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import ReactPaginate from "react-paginate";

import FilteredSearchBar from "./elements/FilteredSearchBar";
import Table from "../common/Table";
import RolesList from "./elements/RolesList";
import { filterRoleEmp, sortRole } from "../../API/role";

const RoleManagement = () => {
  const [roles, setRoles] = useState([]);
  const [sort, setSort] = useState("Alphabetical");
  const [skeleton, setSkeleton] = useState(null);
  const [filter, setFilter] = useState({ dept: [], empType: [] });
  const [loading, setLoading] = useState(false);
  // const [searchValue, setSearchValue] = useState("");

  const navigation = useNavigate();

  const handlePageClick = (data) => {
    console.log("handlePageClick", data.selected);
    let currentPage = data.selected + 1;
  };

  function getSortedRoles() {
    console.log("getSorted");
    setLoading(true);
    sortRole(sort)
      .then(function (response) {
        // console.log(response);
        if (response?.data?.msg === "Role does not exist") {
          setRoles([]);
        } else {
          // console.log(response);
          setRoles(response?.data?.msg ?? []);
        }
        setLoading(false);
        setSkeleton(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    getSortedRoles();
  }, [sort]);

  function getFilteredRoles(dept, emp) {
    setLoading(true);
    filterRoleEmp(emp, dept)
      .then(function (response) {
        if (response?.data?.msg === "Role does not exist") {
          setRoles([]);
        } else {
          console.log(response);
          setRoles(response?.data?.result ?? []);
        }
        setLoading(false);
        setSkeleton(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    if (filter.empType.length === 0 && filter.dept.length === 0) {
      getSortedRoles();
    } else {
      getFilteredRoles(filter.dept, filter.empType);
    }
  }, [filter]);

  function getSearchResult(value) {
    if (!value) {
      getSortedRoles();
    } else {
      setRoles(() => {
        return roles?.filter((role) => {
          return (
            role?.employee_type?.includes(value) ||
            role?.department?.includes(value)
          );
        });
      });
    }
  }

  return (
    <Page>
      <div className="my-1 p-1 items-center  sm:my-3 flex justify-between">
        <p class="text-lg font-semibold font-OpenSans">Roles</p>

        <button
          onClick={() => navigation("/RoleManagement/add/test-123")}
          className="px-8 py-1   border-2 border-buttonColor rounded-lg bg-[#3C69DF] hover:bg-[#284ba3] text-white text-bold"
        >
          + Create Role
        </button>
      </div>

      <FilteredSearchBar
        title="Enter Department or Employee type"
        sort={sort}
        setSort={setSort}
        filter={filter}
        setFilter={setFilter}
        onSearch={(value) => {
          getSearchResult(value);
        }}
        searchBar
        filterDepartment
      />

      <Table
        headTitles={[
          "Department",
          "Employee Type",
          "Created on",
          "Documents req.",
        ]}
      >
        {!skeleton && (
          <Skeleton
            count={10}
            style={{
              display: "grid",
              height: 45,
              width: 1500,
              marginTop: "1rem",
            }}
          />
        )}
        {skeleton && <RolesList roles={roles} setRoles={setRoles} />}
      </Table>
      {roles.length == 0 && (
        <div className="h-screen w-full mt-5">
          <div className="flex justify-center items-center">
            <p className="font-bold text-2xl text-gray-500">No Record Found</p>
          </div>
        </div>
      )}
    </Page>
  );
};
export default RoleManagement;
